<footer>
  <div class="footer-container">
    <div class="top-part">
      <div class="left-side">
        <div class="logo-wrapper">
          <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <p class="mt-12">{{ 'footer.more_than' | translate }}</p>
      </div>

      <div class="right-side">
        <div class="list-items-container">
          <!--<div class="list-item">
            <div>Learn</div>
            <ul>
              <li><a routerLink="/">How to write a Resume</a></li>
              <li><a routerLink="/">How to write a Cover Letter</a></li>
            </ul>
          </div>-->

          <div class="list-item">
            <div> {{ 'footer.Resources' | translate }}</div>
            <ul>
              <li>
                <a href="https://www.selfcv.com/career-blog/" target="_blank" rel="noopener">
                  {{ 'footer.Blog' | translate }}
                </a>
              </li>
              <li>
                <a href="https://www.selfcv.com/tools/" target="_blank" rel="noopener">
                  {{ 'footer.Tools' | translate }}
                </a>
              </li>
            </ul>
          </div>

          <div class="list-item">
            <div>{{ 'footer.Tools' | translate }}</div>
            <ul>
              <li>
                <a href="https://www.selfcv.com/tools/crop-image/" target="_blank" rel="noopener">
                  {{ 'footer.Crop_Image' | translate }}
                </a>
              </li>
              <li>
                <a href="https://www.selfcv.com/tools/convert-base64-to-pdf/" target="_blank" rel="noopener">
                  {{ 'footer.Base64_PDF' | translate }}
                </a>
              </li>
              <li>
                <a href="https://www.selfcv.com/tools/convert-pdf-to-base64/" target="_blank" rel="noopener">
                  {{ 'footer.PDF_Base64' | translate }}
                </a>
              </li>
              <li>
                <a href="https://www.selfcv.com/tools/download-youtube-thumbnail/" target="_blank" rel="noopener">
                  {{ 'footer.YouTube_Thumbnail' | translate }}
                </a>
              </li>
              <li>
                <a href="https://www.selfcv.com/tools/" target="_blank" rel="noopener">
                  {{ 'footer.More_Tools' | translate }}
                </a>
              </li>
            </ul>
          </div>

          <div class="list-item">
            <div> {{ 'footer.Get_Started' | translate }}</div>
            <ul>
              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'cv_templates' : appStateService.dateTime">
                  {{ 'footer.Resume_Examples' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'cover_letters' : appStateService.dateTime">
                  {{ 'footer.Cover_Letter_Examples' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'pricing' : appStateService.dateTime">
                  {{ 'footer.Pricing' | translate }}
                </a>
              </li>
            </ul>
          </div>

          <div class="list-item">
            <div>{{ 'footer.Contact' | translate }}</div>
            <ul>
              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'about_us' : appStateService.dateTime" routerLinkActive="active">
                  {{ 'footer.About_Us' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'contact_us' : appStateService.dateTime" routerLinkActive="active">
                  {{ 'footer.Contact_Us' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'terms_of_use' : appStateService.dateTime" routerLinkActive="active">
                  {{ 'footer.Terms_Service' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'privacy_policy' : appStateService.dateTime" routerLinkActive="active">
                  {{ 'footer.Privacy_Policy' | translate }}
                </a>
              </li>

              <li>
                <a [routerLink]="appStateService.route_paths | lngRoute: 'refund_policy' : appStateService.dateTime" routerLinkActive="active">
                  {{ 'footer.Refund_Policy' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

      <div class="languages-part">
        <div class="sf-dropdown-wrapper w-150">
          <sf-dropdown
            [unSelectable]="false"
            [hasSearch]="false"
            [additionalClassName]="'lng-dropdown'"
            [selectedItem]="appStateService.selectedLanguage"
            [items]="appStateService.languages"
            (select$)="changeLanguage($event)"
          />
        </div>
      </div>

    <div class="bottom-part">
      <div>{{ projectMessages.allRights | translate }}</div>

      <sf-project-social-icons/>
    </div>

  </div>
</footer>
