import {Directive, ElementRef, HostListener, inject, Input} from '@angular/core';
import {NzMessageService} from "ng-zorro-antd/message";
import {TranslateService} from "@ngx-translate/core";

@Directive({
  selector: '[sfContentEditableMaxLength]',
  standalone: true
})
export class ContentEditableMaxLengthDirective {
  private readonly elementRef = inject(ElementRef);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);

  @Input({required: true, alias: 'sfContentEditableMaxLength'}) public maxLength = 2000;
  @Input() public maxLines = 10;

  constructor() {
  }

  @HostListener('keydown', ['$event']) onKeyDown($event: ClipboardEvent) {
    const textEl = ($event.target as HTMLElement);

    if (textEl.innerText.trim().length === 0) {
      textEl.innerHTML = '';
    }
  }

  @HostListener('input') onInput() {
    this.checkContent();
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    this.handlePaste(event);
  }

  private checkContent() {
    let text = this.elementRef.nativeElement.innerText;
    if (text.length > this.maxLength) {
      text = text.substring(0, this.maxLength);
      this.elementRef.nativeElement.innerText = text;
      this.setCaretToEnd();
    }

    this.limitLineBreaks();
  }

  private handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    let pasteText = event.clipboardData?.getData('text');
    const currentText = this.elementRef.nativeElement.innerText;

    if (currentText.length + pasteText?.length > this.maxLength) {
      pasteText = pasteText?.substring(0, this.maxLength - currentText.length);
      this.nzMessageService.info(
        this.translateService.instant('project_messages.too_big_size')
      );
    }

    document.execCommand('insertText', false, pasteText);

    this.limitLineBreaks();
  }

  private limitLineBreaks() {
    let html = this.elementRef.nativeElement.innerHTML;
    const lineBreaks = html.match(/<br\s*\/?>|<div><br\s*\/?><\/div>/gi) || [];

    if (lineBreaks.length > this.maxLines) {
      html = html.replace(/<br\s*\/?>|<div><br\s*\/?><\/div>/gi, (match: string) => {
        if (lineBreaks.length <= this.maxLines) return match;
        lineBreaks.pop();
        this.nzMessageService.info(
          this.translateService.instant('project_messages.too_big_size')
        );
        return '';
      });

      this.elementRef.nativeElement.innerHTML = html;
      this.setCaretToEnd();
    }
  }

  private setCaretToEnd() {
    const element = this.elementRef.nativeElement;
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
    element.focus();
  }

}
