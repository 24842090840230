import {TmClassnames} from "@app/shared/enums";
import {inject, Injectable} from '@angular/core';
import {AppStateService} from "@app/shared/services";
import {allSectionsDefaults, defaultEmptyPage} from "@app/shared/constants";
import {cloneObject, getElHeightWithMargins, isListSection} from "@app/shared/helpers";
import {TemplateConfigs, TemplateSection, TemplateSectionItem} from "@app/shared/interfaces";

const pagePaddingTop = 50;

@Injectable({
  providedIn: 'root'
})
export class OneRowTmSplitLogicService {
  private readonly appStateService = inject(AppStateService);


  constructor() {
  }

  public oneRowSplitPages(template: TemplateConfigs) {
    const templateEl: HTMLDivElement = document.querySelector('.template-main-container')!;

    const pageELList = templateEl.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);

    const sf_line_el = templateEl.querySelector(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`)?.querySelector('.sf-line') as HTMLElement;
    const lineElHeight = getElHeightWithMargins(sf_line_el);

    pageELList.forEach((pageEL, index) => {
      const pageElSizes = pageEL.getBoundingClientRect();

      const pageBodyEl = pageEL.querySelector('.one-row-body');
      const pageBodyElSizes = pageBodyEl?.getBoundingClientRect();

      let size = pageElSizes.height; // 1348

      if (index === 0) {
        size = size - (pageBodyElSizes!.top - pageElSizes.top);
      } else {
        size = size - pagePaddingTop;
      }

      if (pageBodyElSizes!.height > size) {
        this.addSectionLogic(template, index);
      } else {

        if (pageELList[index + 1]) {

          console.log('the case smaller, page index ->', index);

          const page = template.pages.items[index + 1];

          if (!page) {
            return;
          }

          const nextPageEl = pageELList[index + 1];

          const pageFirstSection = page.section.items[0];

          if (!pageFirstSection) {
            if (page.section.items.length === 0) {
              template.pages.items.splice(index + 1, 1);
              this.appStateService.saveChanges();
            }
            return;
          }

          const prevPage = template.pages.items[index];
          const prevPageLastSection = prevPage.section.items[prevPage.section.items.length - 1];

          const firstSecEL = nextPageEl.querySelector(`.one-row-body`)?.children[0];
          const firstSecELSizes = firstSecEL!.getBoundingClientRect();

          if (isListSection(pageFirstSection)) {

            console.log('list section case');

            if ((pageBodyElSizes!.height + firstSecELSizes.height) + lineElHeight <= size) {

              console.log('go back list');

              page.section.items.shift();
              prevPage.section.items.push(pageFirstSection);
              this.appStateService.saveChanges();

              if (page.section.items.length === 0) {
                template.pages.items.splice(index + 1, 1);
              }
            }
          } else {

            console.log('NON list section case');

            if (pageFirstSection.type === prevPageLastSection?.type) {
              const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
              const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

              console.log('same type');

              if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + 12 <= size) {

                const first = pageFirstSection.items.shift() as TemplateSectionItem;

                prevPageLastSection.items = [...prevPageLastSection.items, first];

                if (pageFirstSection.items.length === 0) {
                  page.section.items.splice(0, 1);
                  this.appStateService.saveChanges();
                }

                if (page.section.items.length === 0) {
                  template.pages.items.splice(index + 1, 1);
                  this.appStateService.saveChanges();
                }

              }
            } else {

              console.log('Dif Types');

              if (pageFirstSection.items.length === 1) {

                console.log('length is 1');
                const oneRowBodyEl = nextPageEl.querySelector(`.one-row-body`);
                const lastSecEL = oneRowBodyEl?.children[0];
                const lastSecELSizes = lastSecEL!.getBoundingClientRect();

                if ((pageBodyElSizes!.height + lastSecELSizes.height) + lineElHeight <= size) {

                  const first = page.section.items.shift() as TemplateSection;

                  prevPage.section.items.push(first);
                  this.appStateService.saveChanges();

                  if (page.section.items.length === 0) {
                    template.pages.items.splice(index + 1, 1);
                    this.appStateService.saveChanges();
                  }
                }
              } else {

                console.log('Length is bigger');

                const workExpPartTitleEL: HTMLDivElement | null = firstSecEL!.querySelector('.work-experience-part .w-e-t-w-t');
                const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
                const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

                const blockTitle = workExpPartTitleEL?.clientHeight || 44;
                console.log('blockTitle', blockTitle);

                if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + blockTitle + lineElHeight <= size) {
                  const first = pageFirstSection.items.shift() as TemplateSectionItem;

                  const newSection = cloneObject(allSectionsDefaults[pageFirstSection.type]);
                  newSection.items = [first];

                  prevPage.section.items.push(newSection);
                  this.appStateService.saveChanges();
                }

              }

            }

          }

        }

      }

    });
  }

  private addSectionLogic(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const lastSection = page?.section.items[page.section.items.length - 1];

    if (!lastSection) {
      return;
    }

    if (isListSection(lastSection)) {
      this.addListSection(template, index);
    } else {
      this.addNotListSection(template, index);
    }
  }

  private addListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.section.items.pop() as TemplateSection;

    if (!nextPage) {
      const defaultPage = cloneObject(defaultEmptyPage);
      defaultPage.section.items = [lastSection];

      template.pages.items.push(defaultPage);
      this.appStateService.saveChanges();
    } else {
      nextPage.section.items.unshift(lastSection);
      this.appStateService.saveChanges();
    }

    this.appStateService.saveChanges();
  }

  private addNotListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.section.items[page.section.items.length - 1];
    const lastSectionLastItem = lastSection.items[lastSection.items.length - 1];

    if (!nextPage) {
      const newPage = cloneObject(defaultEmptyPage);

      if (lastSection.items.length === 1) {
        newPage.section.items = [lastSection];
        page.section.items.pop();
      } else {
        const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
        newSection.items = [lastSectionLastItem];
        newSection.title = lastSection.title;
        newPage.section.items = [newSection];
        lastSection.items.pop();
      }

      template.pages.items.push(newPage);
      this.appStateService.saveChanges();
      console.log('next page added');
    } else {

      const nextPageFirstSection = nextPage.section.items[0];

      if (lastSection.items.length === 1) {
        if (nextPageFirstSection.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          page.section.items.pop();
          this.appStateService.saveChanges();
        } else {
          nextPage.section.items.unshift(lastSection);
          page.section.items.pop();
          this.appStateService.saveChanges();
        }
        this.appStateService.saveChanges();
      } else {
        if (nextPageFirstSection?.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.section.items.unshift(newSection);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        }
      }

    }

  }

}
