import {Observable, of} from "rxjs";
import * as PDFJS from 'pdfjs-dist';
import {extractRawText} from "mammoth";
import {map, switchMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ElementRef, inject, Injectable, TemplateRef} from '@angular/core';
import {allSectionsItemsDefaults, sectionAchievement} from "@app/shared/constants";
import {TemplateSectionTypes, TemplateTypes, TmSocialInfos} from "@app/shared/enums";
import {cloneObject, createFormData, findTmTotalSectionByType} from "@app/shared/helpers";
import {
  TemplateConfigs,
  UploadedCertificates,
  UploadedCV,
  UploadedEducation,
  UploadedWorkExp
} from "@app/shared/interfaces";
import {TranslateService} from "@ngx-translate/core";

interface ObserverData {
  text: string;
  pagesCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadExistingCvService {
  private readonly httpClient = inject(HttpClient);
  private readonly nzModalService = inject(NzModalService);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly nzNotificationService = inject(NzNotificationService);

  private uploadCvModalRef: NzModalRef | null = null;

  constructor() {
    PDFJS.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.7.76/pdf.worker.min.mjs";
  }

  public getPdfUrlFromFile(file: NzUploadFile) {
    return this.httpClient.post<{ src: string }>('api/v1/migrate/upload/resume', createFormData(file as any))
      .pipe(
        switchMap((res) => {
          return this.readPdfFromUrl(res.src)
            .pipe(
              switchMap((res) => {
                if (!res.text.length) {
                  this.nzModalService.closeAll();
                  return of();
                } else {
                  return of(res);
                }
              })
            )
        }),
        switchMap((res) => this.getTemplateJson(res))
      );
  }

  public readPdfFromUrl(pdfUrl: string): Observable<ObserverData> {
    return new Observable((observer) => {
      const pdf = PDFJS.getDocument(pdfUrl);

      pdf.promise.then((res) => {
        const totalPageCount = res._pdfInfo.numPages;
        const pagesCount = Math.min(totalPageCount, 6);
        const countPromises: Promise<string[]>[] = [];

        for (let currentPage = 1; currentPage <= pagesCount; currentPage++) {
          const page = res.getPage(currentPage);

          const prm: Promise<string[]> = page.then((page) => {
            const textContent = page.getTextContent();

            return textContent.then((text) => {
              // @ts-ignore
              return text.items.map((stringFromDoc) => stringFromDoc?.str);
            });
          });

          countPromises.push(prm);
        }

        return Promise.all(countPromises)
          .then((texts) => {
            // adding space to separate founded words, for easy processing/readability improvement

            const txt = texts.join(' ').trim();

            if (!txt.length) {
              this.nzNotificationService.error(
                'Unable to extract text from the uploaded file. It appears to contain images or non-text content. Please upload a file with readable text.',
                ''
              );
            }

            return txt;
          })
          .then((text) => observer.next({text, pagesCount}));
      });
    });
  }

  public getTemplateJson(data: ObserverData) {
    return this.httpClient.post<UploadedCV>('api/v1/migrate/cv', data.text)
      .pipe(
        map((res) => ({uploadedCV: res, pagesCount: data.pagesCount}))
      );
  }

  public applyUploadedCv(res: UploadedCV, template: TemplateConfigs) {

    template.title.text = res.personalInfo.name;
    template.subTitle.text = res.personalInfo.role;

    if (res.summary.length) {
      template.summary.desc.text = res.summary
    }

    const email = this.getSocialByType(template, TmSocialInfos.EMAIL);
    const linkedin = this.getSocialByType(template, TmSocialInfos.LINKEDIN);
    const phone = this.getSocialByType(template, TmSocialInfos.PHONE);
    const website = this.getSocialByType(template, TmSocialInfos.LINK);
    const location = this.getSocialByType(template, TmSocialInfos.CITY);

    email.text = res.personalInfo.email;
    linkedin.text = res.personalInfo.linkedin;
    phone.text = res.personalInfo.phone;
    location.text = res.personalInfo.location;

    if (res.personalInfo.website) {
      website.text = res.personalInfo.website;
      website.state = true;
    }

    if (res.interests.length) {
      const type = TemplateSectionTypes.INTERESTS;
      const newInterests = this.getNewSecItemForList(res.interests, type);
      const interests = findTmTotalSectionByType(template, type)!;
      interests.items = [...newInterests];
    }

    if (res.skills.length) {
      const type = TemplateSectionTypes.SKILLS;
      const newSkills = this.getNewSecItemForList(res.skills, type);
      const skills = findTmTotalSectionByType(template, type)!;
      skills.items = [...newSkills];
    }

    if (res.languages.length) {
      const type = TemplateSectionTypes.LANGUAGES;
      const newLanguages = this.getNewSecItemForList(res.languages, type);
      const languages = findTmTotalSectionByType(template, type)!;
      languages.items = [...newLanguages];
    }

    if (res.workExperience.length) {
      const type = TemplateSectionTypes.WORK_EXPERIENCE;
      const newWorkExp = this.getNewSecItem_workExp(res.workExperience)
      const workExp = findTmTotalSectionByType(template, type)!;
      workExp.items = [...newWorkExp];
    }

    if (res.education.length) {
      const type = TemplateSectionTypes.EDUCATION;
      const newEducation = this.getNewSecItem_education(res.education)
      const education = findTmTotalSectionByType(template, type)!;
      education.items = [...newEducation];
    }

    if (res.certificates.length) {
      const type = TemplateSectionTypes.CERTIFICATES;
      const newCertificates = this.getNewSecItem_certificates(res.certificates);
      const certificates = findTmTotalSectionByType(template, type)!;
      certificates.items = [...newCertificates];


      const state = template.allSections.items
        .some((section) => section.type === TemplateSectionTypes.CERTIFICATES);

      if (state) {
        switch (template.type) {
          case TemplateTypes.ONE_ROW: {
            template.pages.items[0].section.items.push(certificates);
            break;
          }
          case TemplateTypes.TWO_ROWS: {
            template.pages.items[0].twoRowLeftSideSection.items.push(certificates);
            break;
          }
          case TemplateTypes.SIDEBAR: {
            template.pages.items[0].sidebarSection.items.push(certificates);
            break;
          }
        }

        template.allSections.items = template.allSections.items
          .filter((section) => section.type !== TemplateSectionTypes.CERTIFICATES);
      }

    }
  }

  private getNewSecItemForList(res: string[], type: TemplateSectionTypes) {
    return res
      .map((item) => ({item, sec: cloneObject(allSectionsItemsDefaults[type])}))
      .map((item) => {
        item.sec.text = item.item;
        return item.sec
      });
  }

  private getNewSecItem_workExp(res: UploadedWorkExp[]) {
    const type = TemplateSectionTypes.WORK_EXPERIENCE;

    return res
      .map((item) => ({item, sec: cloneObject(allSectionsItemsDefaults[type])}))
      .map((item) => {
        const workExp = item.sec;
        const uploaded_workExp = item.item;

        workExp.title!.text = uploaded_workExp.position;
        workExp.subTitle!.text = uploaded_workExp.employer;
        workExp.address!.text = uploaded_workExp.location;

        workExp.achievements!.items = uploaded_workExp.achievements
          .map((text) => ({text, hasEditor: true}));

        if (workExp.achievements?.items.length === 0) {
          workExp.achievements.items = [cloneObject(sectionAchievement)];
        }

        workExp.date!.fromMonth = uploaded_workExp.date.fromMonth;
        workExp.date!.fromYear = uploaded_workExp.date.fromYear;
        workExp.date!.toMonth = uploaded_workExp.date.toMonth;
        workExp.date!.toYear = uploaded_workExp.date.toYear;

        return item.sec
      });
  }

  private getNewSecItem_education(res: UploadedEducation[]) {
    const type = TemplateSectionTypes.EDUCATION;

    return res
      .map((item) =>
        ({item, sec: cloneObject(allSectionsItemsDefaults[type])}))
      .map((item) => {
        const education = item.sec;
        const uploaded_education = item.item;

        education.title!.text = uploaded_education.degree;
        education.subTitle!.text = uploaded_education.school;
        education.address!.text = uploaded_education.location;

        education.achievements!.items = uploaded_education.courses
          .map((text) => ({text, hasEditor: true}));

        if (education.achievements?.items.length === 0) {
          education.achievements.items = [cloneObject(sectionAchievement)];
        }

        education.date!.fromMonth = uploaded_education.date.fromMonth;
        education.date!.fromYear = uploaded_education.date.fromYear;
        education.date!.toMonth = uploaded_education.date.toMonth;
        education.date!.toYear = uploaded_education.date.toYear;

        return item.sec
      });
  }

  private getNewSecItem_certificates(res: UploadedCertificates[]) {
    const type = TemplateSectionTypes.CERTIFICATES;

    return res
      .map((item) => ({item, sec: cloneObject(allSectionsItemsDefaults[type])}))
      .map((item) => {
        const certificates = item.sec;
        const uploaded_certificates = item.item;

        certificates.title!.text = uploaded_certificates.title;
        certificates.subTitle!.text = uploaded_certificates.subTitle;

        certificates.date!.fromMonth = uploaded_certificates.date.fromMonth;
        certificates.date!.fromYear = uploaded_certificates.date.fromYear;
        certificates.date!.toMonth = uploaded_certificates.date.toMonth;
        certificates.date!.toYear = uploaded_certificates.date.toYear;

        return item.sec
      });
  }

  private getSocialByType(template: TemplateConfigs, type: TmSocialInfos) {
    return template.socialInfo.items.find((item) => item.type === type)!;
  }

  public openUploadCvBanner(uploadCVCheckModalRef: TemplateRef<ElementRef<HTMLDivElement>>) {
    this.uploadCvModalRef = this.nzModalService.info({
      nzWrapClassName: 'upload-cv-check-modal',
      nzContent: uploadCVCheckModalRef,
      nzTitle: this.translateService.instant('project_messages.uploading_your_resume'),
      nzOkText: null,
      nzClosable: false
    });
  }

  public changeLoaderMessages() {
    setTimeout(() => {
      if (this.uploadCvModalRef) {
        this.uploadCvModalRef.getConfig().nzTitle = this.translateService.instant('builder.processing_your_data');
      }
    }, 3000);

    setTimeout(() => {
      if (this.uploadCvModalRef) {
        this.uploadCvModalRef.getConfig().nzTitle = this.translateService.instant('builder.almost_there_upload');
      }
    }, 6000);

    setTimeout(() => {
      if (this.uploadCvModalRef) {
        this.uploadCvModalRef.getConfig().nzTitle = this.translateService.instant('builder.finalizing_upload');
      }
    }, 9000);
  }

  public uploadCvFailed() {
    this.nzModalService.closeAll();
    this.nzMessageService.error(this.translateService.instant('project_messages.something_went_wrong'));
  }

  public uploadFinished() {
    this.nzModalService.closeAll();
    this.uploadCvModalRef = null;
  }

  /* Txt file */

  public on_Txt_FileSelected(file: NzUploadFile) {
    const result$: Observable<ObserverData> = new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result as string;
        observer.next({
          text: fileContent,
          pagesCount: 6
        });
      };
      reader.readAsText(file as any);
    });

    return result$.pipe(switchMap((res) => this.getTemplateJson(res)));
  }

  /** .docx file */
  public on_DOCX_FileSelected(file: NzUploadFile) {

    const result$: Observable<ObserverData> = new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;

        extractRawText({arrayBuffer: arrayBuffer as ArrayBuffer})
          .then((result) => {
            const extractedText = result.value; // The extracted text is in result.value

            observer.next({
              text: extractedText,
              pagesCount: 6
            });

          })
          .catch((error) => {
            this.nzMessageService.error(this.translateService.instant('project_messages.error_extracting_DOCX'));
            console.error("Error extracting text from DOCX file:", error);
            this.nzModalService.closeAll();
          });
      };

      reader.readAsArrayBuffer(file as any);
    });

    return result$.pipe(switchMap((res) => this.getTemplateJson(res)));
  }

}
