<div class="premium-plans pl-right-side {{parentClassname}}">
  <div class="premium">
    <h3>{{ 'pricing_page.premium' | translate }}</h3>
    <p>{{ 'pricing_page.pay_once' | translate }}</p>
  </div>

  <div class="plans">

    @if (appStateService.isUserPlansReady) {
      <!--@if (this.appStateService.user?.emailVerified) {
        @if (appStateService.user?.referAndEarn?.expired && appStateService.user?.referAndEarn?.eligible) {
          <div class="plan-item one-month-free" (click)="onSelectOneMonthFree()">
            <i class="ph ph-gift"></i>
            Activate one month for free
          </div>
        }
      }-->

      @for (price of countryPlan.prices; track price.id) {
        <div class="plan-item">
          <div class="pl-date">{{ price.description | translate }}</div>

          <div class="pl-content">

            <div class="pl-price">
              @if (countryPlan.currency === 'AMD') {
                <span>
                  <span class="arm-cc-code">{{ countryPlan.currencyCode }}</span>
                  {{ price.totalPrice }}
                  <span class="arm-cc">{{ countryPlan.currency }}</span>
                </span>
              } @else {
                <span>{{ countryPlan.currencyCode }} {{ price.totalPrice }}</span>
              }
              <span>
                  @if (price.savePercentage) {
                    {{ 'pricing_page.SAVE' | translate }} {{ price.savePercentage }}
                  }
              </span>
            </div>

            <div class="get-started">
              <button class="sf-btn-primary" (click)="onUpgradePlan(price)">
                {{ 'pricing_page.upgrade' | translate }}
              </button>
            </div>
          </div>

          @if (price.popular) {
            <div class="most-popular">{{ 'pricing_page.most_popular' | translate }}</div>
          }
        </div>
      }
    } @else {
      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 7 }"/>
    }
  </div>

  <div class="pl-includes">
    <h3>{{ 'pricing_page.plan_includes' | translate }}</h3>

    <ul>
      @for (item of appStateService.premiumBillingPlanIncludes; let i = $index; track i) {
        <li class="mb-4">{{ item.title | translate }}</li>
      }
    </ul>
  </div>

</div>
