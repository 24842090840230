import {RoutePaths, Routes_seo, SEO_Configs} from "@app/shared/interfaces";

export const route_paths: Readonly<RoutePaths> = {
  en: {
    // auth
    auth: '/auth',
    sign_up: '/auth/sign-up',
    forgot_password: '/auth/forgot-password',
    sign_in: '/auth/sign-in',
    // home
    home: '/',
    cv_templates: '/cv-templates',
    cover_letters: '/cover-letters',
    pricing: '/pricing',
    about_us: '/about-us',
    contact_us: '/contact-us',
    terms_of_use: '/terms-of-use',
    privacy_policy: '/privacy-policy',
    refund_policy: '/refund-policy',
    // account
    account: '/account',
    user: '/account/user',
    choose_plan: '/account/choose-plan',
    billing_history: '/account/billing-history',
    security: '/account/security',
    // builder
    dashboard: '/dashboard',
    documents: '/documents',
  },
  ru: {
    // auth
    auth: '/ru/avtorizatsiya',
    sign_up: '/ru/avtorizatsiya/registratsiya',  // Registration
    forgot_password: '/ru/avtorizatsiya/vosstanovlenie-parolya',  // Password reset
    sign_in: '/ru/avtorizatsiya/vxod',  // Login (sign-in)
    // home
    home: '/ru',
    cv_templates: '/ru/shablony-rezyume',  // CV Templates
    cover_letters: '/ru/soprovoditelnie-pisma',  // Cover Letters
    pricing: '/ru/tseny',  // Pricing
    about_us: '/ru/o-nas',  // About Us
    contact_us: '/ru/kontakty',  // Contact Us
    terms_of_use: '/ru/usloviya-ispolzovaniya',  // Terms of Use
    privacy_policy: '/ru/politika-konfidencialnosti',  // Privacy Policy
    refund_policy: '/ru/politika-vozvratov',  // Refund Policy
    // account
    account: '/ru/akkaunt',  // Account
    user: '/ru/akkaunt/polzovatel',  // User
    choose_plan: '/ru/akkaunt/vybor-plana',  // Choose Plan
    billing_history: '/ru/akkaunt/istoriya-platezhey',  // Billing History
    security: '/ru/akkaunt/bezopasnost',  // Security
    // builder
    dashboard: '/ru/panel-upravleniya',  // Dashboard
    documents: '/ru/dokumenti'  // Documents
  },
  fr: {
    // auth
    auth: '/fr/authentification',
    sign_up: '/fr/authentification/inscription',
    forgot_password: '/fr/authentification/mot-de-passe-oublie',
    sign_in: '/fr/authentification/connexion',
    // home
    home: '/fr',
    cv_templates: '/fr/modeles-de-cv',
    cover_letters: '/fr/lettres-de-motivation',
    pricing: '/fr/tarifs',
    about_us: '/fr/a-propos',
    contact_us: '/fr/nous-contacter',
    terms_of_use: '/fr/conditions-utilisation',
    privacy_policy: '/fr/politique-confidentialite',
    refund_policy: '/fr/politique-remboursement',
    // account
    account: '/fr/compte',
    user: '/fr/compte/utilisateur',
    choose_plan: '/fr/compte/choisir-un-plan',
    billing_history: '/fr/compte/historique-paiement',
    security: '/fr/compte/securite',
    // builder
    dashboard: '/fr/tableau-de-bord',
    documents: '/fr/documents'
  },
  de: {
    // auth
    auth: '/de/auth',
    sign_up: '/de/auth/registrieren',
    forgot_password: '/de/auth/passwort-vergessen',
    sign_in: '/de/auth/einloggen',
    // home
    home: '/de',
    cv_templates: '/de/cv-vorlagen',
    cover_letters: '/de/bewerbungsschreiben',
    pricing: '/de/preise',
    about_us: '/de/ueber-uns',
    contact_us: '/de/kontakt',
    terms_of_use: '/de/nutzungsbedingungen',
    privacy_policy: '/de/datenschutzrichtlinie',
    refund_policy: '/de/rueckerstattungsrichtlinie',
    // account
    account: '/de/konto',
    user: '/de/konto/benutzer',
    choose_plan: '/de/konto/abo-waehlen',
    billing_history: '/de/konto/zahlungsverlauf',
    security: '/de/konto/sicherheit',
    // builder
    dashboard: '/de/dashboard',
    documents: '/de/dokumente',
  },
  es: {
    // auth
    auth: '/es/autenticacion',
    sign_up: '/es/autenticacion/registrarse',
    forgot_password: '/es/autenticacion/olvido-contraseña',
    sign_in: '/es/autenticacion/iniciar-sesion',
    // home
    home: '/es',
    cv_templates: '/es/plantillas-cv',
    cover_letters: '/es/cartas-de-presentacion',
    pricing: '/es/precios',
    about_us: '/es/acerca-de-nosotros',
    contact_us: '/es/contacto',
    terms_of_use: '/es/terminos-de-uso',
    privacy_policy: '/es/politica-de-privacidad',
    refund_policy: '/es/politica-de-reembolso',
    // account
    account: '/es/cuenta',
    user: '/es/cuenta/usuario',
    choose_plan: '/es/cuenta/elegir-plan',
    billing_history: '/es/cuenta/historial-facturacion',
    security: '/es/cuenta/seguridad',
    // builder
    dashboard: '/es/panel-de-control',
    documents: '/es/documentos',
  }
};

export const routes_seo: Readonly<Routes_seo> = {
  en: {
    home: {
      title: 'selfcv | Free Resume Builder',
    },
    cv_templates: {
      title: 'selfcv | Free Resume Builder | CV Templates',
    },
    cover_letters: {
      title: 'selfcv | Free Resume Builder | Cover Letters',
    },
    pricing: {
      title: 'selfcv | Free Resume Builder | Pricing',
    },
    sign_in: {
      title: 'selfcv | Free Resume Builder | Sign In',
    },
    sign_up: {
      title: 'selfcv | Free Resume Builder | Sign Up',
    },
    forgot_password: {
      title: 'selfcv | Free Resume Builder | Forgot Password',
    },
    reset_password: {
      title: 'selfcv | Free Resume Builder | Reset Password',
    },
    account_user: {
      title: 'selfcv | Free Resume Builder | User Account',
    },
    account_security: {
      title: 'selfcv | Free Resume Builder | Account Security',
    },
    account_billing_history: {
      title: 'selfcv | Free Resume Builder | Billing History',
    },
    account_choose_plan: {
      title: 'selfcv | Free Resume Builder | Choose Plan',
    },
    dashboard: {
      title: 'selfcv | Free Resume Builder | Dashboard'
    },
    builder: {
      title: 'selfcv | Free Resume Builder | Builder',
    },
    terms_of_use: {
      title: 'selfcv | Free Resume Builder | Terms Of Use',
    },
    refund_policy: {
      title: 'selfcv | Free Resume Builder | Refund Policy',
    },
    confirm_email: {
      title: 'selfcv | Free Resume Builder | Confirm Email',
    },
    privacy_policy: {
      title: 'selfcv | Free Resume Builder | Privacy Policy',
    },
    contact_us: {
      title: 'selfcv | Free Resume Builder | Contact Us',
    },
    about_us: {
      title: 'selfcv | Free Resume Builder | About Us',
    }
  },
  ru: {
    home: {
      title: 'selfcv | Бесплатный Конструктор Резюме',
    },
    cv_templates: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Шаблоны Резюме',
    },
    cover_letters: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Сопроводительные Письма',
    },
    pricing: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Цены',
    },
    sign_in: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Вход',
    },
    sign_up: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Регистрация',
    },
    forgot_password: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Восстановление Пароля',
    },
    reset_password: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Сброс Пароля',
    },
    account_user: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Аккаунт Пользователя',
    },
    account_security: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Безопасность Аккаунта',
    },
    account_billing_history: {
      title: 'selfcv | Бесплатный Конструктор Резюме | История Платежей',
    },
    account_choose_plan: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Выбор Тарифа',
    },
    dashboard: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Панель Управления',
    },
    builder: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Конструктор',
    },
    terms_of_use: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Условия Использования',
    },
    refund_policy: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Политика Возвратов',
    },
    confirm_email: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Подтверждение Электронной Почты',
    },
    privacy_policy: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Политика Конфиденциальности',
    },
    contact_us: {
      title: 'selfcv | Бесплатный Конструктор Резюме | Свяжитесь С Нами',
    },
    about_us: {
      title: 'selfcv | Бесплатный Конструктор Резюме | О Нас',
    }
  },
  fr: {
    home: {
      title: 'selfcv | Générateur de CV Gratuit',
    },
    cv_templates: {
      title: 'selfcv | Générateur de CV Gratuit | Modèles de CV',
    },
    cover_letters: {
      title: 'selfcv | Générateur de CV Gratuit | Lettres de Motivation',
    },
    pricing: {
      title: 'selfcv | Générateur de CV Gratuit | Tarifs',
    },
    sign_in: {
      title: 'selfcv | Générateur de CV Gratuit | Connexion',
    },
    sign_up: {
      title: 'selfcv | Générateur de CV Gratuit | Inscription',
    },
    forgot_password: {
      title: 'selfcv | Générateur de CV Gratuit | Mot de Passe Oublié',
    },
    reset_password: {
      title: 'selfcv | Générateur de CV Gratuit | Réinitialisation du Mot de Passe',
    },
    account_user: {
      title: 'selfcv | Générateur de CV Gratuit | Compte Utilisateur',
    },
    account_security: {
      title: 'selfcv | Générateur de CV Gratuit | Sécurité du Compte',
    },
    account_billing_history: {
      title: 'selfcv | Générateur de CV Gratuit | Historique de Paiement',
    },
    account_choose_plan: {
      title: 'selfcv | Générateur de CV Gratuit | Choisir un Abonnement',
    },
    dashboard: {
      title: 'selfcv | Générateur de CV Gratuit | Tableau de Bord',
    },
    builder: {
      title: 'selfcv | Générateur de CV Gratuit | Générateur',
    },
    terms_of_use: {
      title: 'selfcv | Générateur de CV Gratuit | Conditions d\'Utilisation',
    },
    refund_policy: {
      title: 'selfcv | Générateur de CV Gratuit | Politique de Remboursement',
    },
    confirm_email: {
      title: 'selfcv | Générateur de CV Gratuit | Confirmation d\'E-mail',
    },
    privacy_policy: {
      title: 'selfcv | Générateur de CV Gratuit | Politique de Confidentialité',
    },
    contact_us: {
      title: 'selfcv | Générateur de CV Gratuit | Nous Contacter',
    },
    about_us: {
      title: 'selfcv | Générateur de CV Gratuit | À Propos de Nous',
    }
  },
  de: {
    home: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator',
    },
    cv_templates: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Lebenslauf-Vorlagen',
    },
    cover_letters: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Bewerbungsschreiben',
    },
    pricing: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Preise',
    },
    sign_in: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Anmeldung',
    },
    sign_up: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Registrierung',
    },
    forgot_password: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Passwort Vergessen',
    },
    reset_password: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Passwort Zurücksetzen',
    },
    account_user: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Benutzerkonto',
    },
    account_security: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Kontosicherheit',
    },
    account_billing_history: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Zahlungsübersicht',
    },
    account_choose_plan: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Plan Auswählen',
    },
    dashboard: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Dashboard',
    },
    builder: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Generator',
    },
    terms_of_use: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Nutzungsbedingungen',
    },
    refund_policy: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Rückerstattungsrichtlinie',
    },
    confirm_email: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | E-Mail Bestätigung',
    },
    privacy_policy: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Datenschutzerklärung',
    },
    contact_us: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Kontaktieren Sie uns',
    },
    about_us: {
      title: 'selfcv | Kostenloser Lebenslauf-Generator | Über uns',
    }
  },
  es: {
    home: {
      title: 'selfcv | Generador de CV Gratuito',
    },
    cv_templates: {
      title: 'selfcv | Generador de CV Gratuito | Plantillas de CV',
    },
    cover_letters: {
      title: 'selfcv | Generador de CV Gratuito | Cartas de Presentación',
    },
    pricing: {
      title: 'selfcv | Generador de CV Gratuito | Precios',
    },
    sign_in: {
      title: 'selfcv | Generador de CV Gratuito | Iniciar Sesión',
    },
    sign_up: {
      title: 'selfcv | Generador de CV Gratuito | Registrarse',
    },
    forgot_password: {
      title: 'selfcv | Generador de CV Gratuito | Olvidé mi Contraseña',
    },
    reset_password: {
      title: 'selfcv | Generador de CV Gratuito | Restablecer Contraseña',
    },
    account_user: {
      title: 'selfcv | Generador de CV Gratuito | Cuenta de Usuario',
    },
    account_security: {
      title: 'selfcv | Generador de CV Gratuito | Seguridad de la Cuenta',
    },
    account_billing_history: {
      title: 'selfcv | Generador de CV Gratuito | Historial de Facturación',
    },
    account_choose_plan: {
      title: 'selfcv | Generador de CV Gratuito | Elegir Plan',
    },
    dashboard: {
      title: 'selfcv | Generador de CV Gratuito | Panel de Control'
    },
    builder: {
      title: 'selfcv | Generador de CV Gratuito | Constructor',
    },
    terms_of_use: {
      title: 'selfcv | Generador de CV Gratuito | Términos de Uso',
    },
    refund_policy: {
      title: 'selfcv | Generador de CV Gratuito | Política de Reembolso',
    },
    confirm_email: {
      title: 'selfcv | Generador de CV Gratuito | Confirmar Correo Electrónico',
    },
    privacy_policy: {
      title: 'selfcv | Generador de CV Gratuito | Política de Privacidad',
    },
    contact_us: {
      title: 'selfcv | Generador de CV Gratuito | Contacto',
    },
    about_us: {
      title: 'selfcv | Generador de CV Gratuito | Sobre Nosotros',
    }
  }
};

export const project_SEO_Configs: Readonly<SEO_Configs> = {
  en: {
    title: 'selfcv | Free Resume Builder',
    description: `Build your professional CV online with our easy-to-use Free Resume Builder.
                  Stand out with custom templates, expert tips, and tailored guidance for job success.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, cv generator, free cv generator, online free cv generator,
               CV builder, resume builder, online CV, professional resume, custom templates, CV tips, build cv for free,
               build cv online for free, resume generator for free`,
    url: "https://www.selfcv.com",
    og: {
      title: 'Resume Builder For 2024 | Free CV Builder | selfcv',
      desc: `Create the Perfect Resume or CV and Land Your Dream Job with Our Free Resume Builder. Select a template.
             Personalize it. Apply to jobs. Get more interviews.`,
      local: `en_US`,
    }
  },
  ru: {
    title: "selfcv | Бесплатный Конструктор Резюме",
    description: `Создайте профессиональное резюме онлайн с помощью нашего удобного конструктора резюме.
                    Выделяйтесь с индивидуальными шаблонами, советами экспертов и персональными рекомендациями для успеха в поиске работы.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, генератор резюме, бесплатный генератор резюме, онлайн генератор резюме бесплатно,
                 конструктор резюме, конструктор CV, онлайн резюме, профессиональное резюме, индивидуальные шаблоны, советы по резюме, создание резюме бесплатно,
                 создание резюме онлайн бесплатно, бесплатный генератор резюме`,
    url: "https://www.selfcv.com/ru",
    og: {
      title: "Конструктор Резюме 2024 | Бесплатный Конструктор CV | selfcv",
      desc: `Создайте идеальное резюме или CV и получите работу своей мечты с нашим бесплатным конструктором резюме. Выберите шаблон.
                 Персонализируйте его. Откликайтесь на вакансии. Получайте больше приглашений на собеседования.`,
      local: "ru_RU"
    }
  },
  fr: {
    title: 'selfcv | Générateur de CV Gratuit',
    description: `Créez votre CV professionnel en ligne avec notre Générateur de CV Gratuit facile à utiliser.
                Démarquez-vous avec des modèles personnalisés, des conseils d'experts et un accompagnement adapté pour réussir votre carrière.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, générateur de CV, générateur de CV gratuit, générateur de CV en ligne gratuit,
             créateur de CV, créateur de CV en ligne, CV professionnel, modèles personnalisés, conseils pour le CV, créer un CV gratuitement,
             créer un CV en ligne gratuitement, générateur de CV gratuit`,
    url: "https://www.selfcv.com/fr",
    og: {
      title: 'Générateur de CV pour 2024 | Créateur de CV Gratuit | selfcv',
      desc: `Créez le CV parfait et décrochez l'emploi de vos rêves avec notre Générateur de CV Gratuit. Choisissez un modèle.
           Personnalisez-le. Postulez à des emplois. Obtenez plus d'entretiens.`,
      local: `fr_FR`,
    }
  },
  de: {
    title: 'selfcv | Kostenloser Lebenslauf-Generator',
    description: `Erstelle deinen professionellen Lebenslauf online mit unserem einfach zu bedienenden kostenlosen Lebenslauf-Generator.
                  Hebe dich hervor mit benutzerdefinierten Vorlagen, Experten-Tipps und maßgeschneiderter Anleitung für den Job-Erfolg.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, Lebenslauf-Generator, kostenloser Lebenslauf-Generator, kostenloser online Lebenslauf-Generator,
               Lebenslauf-Ersteller, Lebenslauf-Baukasten, online Lebenslauf, professioneller Lebenslauf, benutzerdefinierte Vorlagen, Lebenslauf-Tipps,
               Lebenslauf kostenlos erstellen, Lebenslauf kostenlos online erstellen, kostenloser Lebenslauf-Generator`,
    url: "https://www.selfcv.com/de",
    og: {
      title: 'Lebenslauf-Ersteller für 2024 | Kostenloser CV-Generator | selfcv',
      desc: `Erstelle den perfekten Lebenslauf oder CV und lande deinen Traumjob mit unserem kostenlosen Lebenslauf-Generator.
             Wähle eine Vorlage. Personalisiere sie. Bewirb dich auf Jobs. Erhalte mehr Vorstellungsgespräche.`,
      local: `de_DE`,
    }
  },
  es: {
    title: 'selfcv | Generador de CV Gratuito',
    description: `Crea tu CV profesional en línea con nuestro fácil de usar Generador de CV Gratuito.
                  Destaca con plantillas personalizadas, consejos de expertos y orientación personalizada para el éxito laboral.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, generador de cv, generador de cv gratuito, generador de cv en línea gratuito,
               creador de cv, creador de currículum, cv en línea, currículum profesional, plantillas personalizadas, consejos para cv,
               crear cv gratis, crear cv en línea gratis, generador de currículum gratuito`,
    url: "https://www.selfcv.com/es",
    og: {
      title: 'Generador de Currículum Para 2024 | Generador de CV Gratuito | selfcv',
      desc: `Crea el CV o Currículum Perfecto y Consigue el Trabajo de tus Sueños con Nuestro Generador de CV Gratuito.
             Elige una plantilla. Personalízala. Postúlate a empleos. Obtén más entrevistas.`,
      local: `es_ES`,
    }
  }
};
