import {environment} from "@env";
import {FormsModule} from '@angular/forms';
import {LngRoutePipe} from "@app/shared/pipes";
import {AppStateService} from '../../services';
import {Language} from "@app/shared/interfaces";
import {NzIconModule} from 'ng-zorro-antd/icon';
import {AuthService} from "@app/shared/services";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzSelectModule} from 'ng-zorro-antd/select';
import {TranslateModule} from "@ngx-translate/core";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {setItemStore} from "@app/shared/helpers/storage";
import {CouponBannerComponent} from "@app/shared/ui-kits";
import {NzDrawerRef} from "ng-zorro-antd/drawer/drawer-ref";
import {redirectToTranslatedPath} from "@app/shared/helpers";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgOptimizedImage, NgTemplateOutlet} from '@angular/common';
import {NzDrawerModule, NzDrawerService} from 'ng-zorro-antd/drawer';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {projectMessages} from "@app/shared/constants/project-messages";
import {DropdownComponent} from "@app/shared/ui-kits/dropdown/dropdown.component";
import {ProjectSocialIconsComponent} from "@app/shared/ui-kits/project-social-icons/project-social-icons.component";
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'sf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    RouterLink,
    FormsModule,
    NzIconModule,
    NzSelectModule,
    NzDrawerModule,
    NgOptimizedImage,
    RouterLinkActive,
    NzDropDownModule,
    NzDividerModule,
    DropdownComponent,
    NgTemplateOutlet,
    NzPopoverModule,
    NzToolTipModule,
    TranslateModule,
    ProjectSocialIconsComponent,
    LngRoutePipe,
  ],
  standalone: true
})
export class HeaderComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  protected readonly authService = inject(AuthService);
  private readonly nzModalService = inject(NzModalService);
  private readonly nzDrawerService = inject(NzDrawerService);
  protected readonly appStateService = inject(AppStateService);

  protected readonly env = environment.env;
  protected readonly projectMessages = projectMessages;

  @Input() public tmTitle = 'Some Title';
  @Output() public tmTitleChange = new EventEmitter<string>();

  @Input() public hasGetStaredBtn = true;
  @Input() public hasNavbar = true;
  protected emailSentState = false;

  public onTitleEditMode = false
  @ViewChild('TmTitleElRef') private readonly tmTitleElRef: ElementRef<HTMLInputElement> | null = null
  @Input() public hasTmTitle = false;
  @Input() public hasBuilderButtons = false;
  protected bannersState = false;

  @Output() public download$ = new EventEmitter<void>();
  @Output() public preview$ = new EventEmitter<void>();
  @Output() public getTemplateUrl$ = new EventEmitter<void>();
  @Output() public downloadUsImage$ = new EventEmitter<void>();

  protected readonly navigator = window.navigator;

  @Input() public getTemplateUrlLoaderState = false;

  @ViewChild('HeaderSidebarRed') private headerSidebarRed!: TemplateRef<{
    $implicit: any;
    drawerRef: NzDrawerRef;
  }>;
  @ViewChild('SidebarTitleRef') private sidebarTitleRef!: TemplateRef<ElementRef>;
  private drawerRef: NzDrawerRef | null = null;

  ngOnInit(): void {
    setTimeout(() => this.bannersState = true, 5000);

    this.appStateService.closeCouponBanner$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onCloseCouponBanner());
  }

  protected openSidebarMenu() {
    this.drawerRef = this.nzDrawerService.create({
      nzClosable: false,
      nzContent: this.headerSidebarRed,
      nzTitle: this.sidebarTitleRef
    });

    this.drawerRef.afterClose
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((closed: boolean) => {
        if (!closed && closed !== undefined) {
          window.scrollTo(0, 0);
        }
      });
  }

  protected closeSidebarMenu(closed = false): void {
    this.drawerRef?.close(closed);
  }

  protected logOut() {
    this.closeSidebarMenu();

    this.authService.signOut().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.router.navigate([this.appStateService.getLngRout('home')]);

    this.appStateService.user = null;
    this.appStateService.user$.next(null);

    this.appStateService.userTemplates = [];
    this.appStateService.userTemplates$.next([]);

    this.appStateService.logOut$.next();
  }

  protected resentEmail(): void {
    this.emailSentState = true;
    this.authService.resendEmail()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
    setTimeout(() => this.emailSentState = false, 10 * 1000);
  }

  protected onTmTitleEdit() {
    this.onTitleEditMode = true;
    setTimeout(() => this.tmTitleElRef?.nativeElement.focus());
  }

  protected onBlurTmTitle() {
    this.onTitleEditMode = false;
    this.tmTitleChange.emit(this.tmTitle);
  }

  protected onDownload() {
    this.download$.emit();
  }

  public changeLanguage(event: Language) {
    this.appStateService.setLng(event.key);
    this.appStateService.setHtmlLangAttribute(event.key, this.renderer);
    this.appStateService.addMetaTags(this.appStateService.SEO_Configs[event.key], this.renderer);

    redirectToTranslatedPath(this.router, event.key, this.appStateService);
  }

  protected onPreviewTemplate() {
    this.preview$.emit();
  }

  protected getTemplateUrl() {
    this.getTemplateUrl$.emit();
  }

  protected downloadAsImage() {
    this.downloadUsImage$.emit();
  }

  protected onCloseCouponBanner() {
    this.appStateService.couponBannerClosed = true;
    setItemStore('couponBannerClosed', true);
    this.onReferFriend();
  }

  protected onReferFriend() {
    this.nzModalService.create({
      nzContent: CouponBannerComponent,
      nzFooter: null,
      nzClosable: false
    });
  }

}

