<div class="header-container">

  @if (bannersState) {
    <div class="top-banners-wrapper">

      @if (!appStateService.couponBannerClosed && appStateService.user?.referAndEarn?.eligible) {
        <ng-container [ngTemplateOutlet]="CouponBannerRef"></ng-container>
      }

      <!-- TODO fix this later -->
      @if (authService.isAuthenticated && !appStateService.user?.emailVerified) {
        <ng-container [ngTemplateOutlet]="EmailUnverifiedRef"></ng-container>
      }
    </div>
  }

  <header>
    <div class="left-side">

      <div class="logo-wrapper">
        <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime">
          <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
          <span>selfcv</span>
        </a>
      </div>

      @if (hasTmTitle) {
        <ng-container [ngTemplateOutlet]="TemplateTitleEditRef"/>
      }

      @if (hasNavbar) {
        <ng-container [ngTemplateOutlet]="NavbarRef"/>
      }
    </div>

    <div class="right-side">
      <div class="buttons in-header">
        @if (!authService.isAuthenticated) {
          <a [routerLink]="appStateService.route_paths | lngRoute: 'sign_in' : appStateService.dateTime">
            <button class="sf-btn-bg-none w-fit p-tb-0 p-lr-8 mr-12">{{ "header.log_in" | translate }}</button>
          </a>
        }

        @if (hasGetStaredBtn) {
          @if (appStateService.userTemplates.length) {
            <a
              class="sf-btn-primary small"
              [routerLink]="appStateService.route_paths | lngRoute: 'dashboard' : appStateService.dateTime">
              {{ 'header.my_documents' | translate }}
            </a>
          } @else {
            <a
              class="sf-btn-tertiary small get-started"
              [routerLink]="appStateService.route_paths | lngRoute: 'cv_templates' : appStateService.dateTime">
              {{ 'header.get_started' | translate }}
            </a>
          }
        }

        @if (hasBuilderButtons) {
          <ng-container [ngTemplateOutlet]="BuilderProgressStateRef"/>
        }

        @if (authService.isAuthenticated) {
          <ng-container [ngTemplateOutlet]="UserIconRef"/>
        }
      </div>

      <i class="menu-icon" nz-icon nzType="unordered-list" nzTheme="outline" (click)="openSidebarMenu()"></i>
    </div>

  </header>

</div>

<ng-template #TemplateTitleEditRef>

  <nz-divider nzType="vertical"></nz-divider>

  <div class="tm-title-wrapper" id="introjs_edit-cv-title">
    @if (onTitleEditMode) {
      <input type="text" [(ngModel)]="tmTitle" #TmTitleElRef (blur)="onBlurTmTitle()">
    } @else {
      <div (click)="onTmTitleEdit()">
        <div>{{ tmTitle }}</div>
        <i class="ph ph-pencil-simple"></i>
      </div>
    }
  </div>
</ng-template>

<ng-template #BuilderProgressStateRef>
  <div class="builder-buttons">
    @if (!navigator.onLine) {
      <i
        class="ph ph-wifi-slash"
        [nz-popover]="'header.tooltips.offline' | translate"
        [nzPopoverContent]="NoInetDescRef"
        nzPopoverPlacement="rightBottom"
      ></i>

      <ng-template #NoInetDescRef>
        <div [innerHTML]="'header.internet' | translate"></div>
      </ng-template>
    } @else {
      @if (appStateService.templatesLoaderState) {
        <i
          class="ph ph-arrows-clockwise"
          [nz-tooltip]="'header.tooltips.saving' | translate"
          [nzTooltipMouseEnterDelay]="1"
          nzTooltipPlacement="bottom"
        ></i>
      } @else {
        <i
          class="ph ph-check-circle"
          [nz-tooltip]="'header.tooltips.saved' | translate"
          [nzTooltipMouseEnterDelay]="1"
          nzTooltipPlacement="bottom"
        ></i>
      }
    }

    <nz-divider nzType="vertical"></nz-divider>

    <div class="d-flex" id="introjs_builder-actions">
      <button
        [nz-tooltip]="'header.tooltips.download_image' | translate"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="1"
        [class.disabled]="appStateService.templateDownloadAsImageState"
        (click)="downloadAsImage()">
        @if (appStateService.templateDownloadAsImageState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-image"></i>
        }
      </button>

      <button
        [nz-tooltip]="'header.tooltips.get_URL' | translate"
        nzTooltipPlacement="bottom"
        [class.disabled]="appStateService.getTemplateUrlLoaderState"
        (click)="getTemplateUrl()">
        @if (appStateService.getTemplateUrlLoaderState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-share"></i>
        }
      </button>

      <button
        [nz-tooltip]="'header.tooltips.preview' | translate"
        [nzTooltipMouseEnterDelay]="1"
        nzTooltipPlacement="bottom"
        (click)="onPreviewTemplate()">
        <i class="ph ph-eye"></i>
      </button>

      <button
        [nz-tooltip]="'header.tooltips.download' | translate"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="1"
        [class.disabled]="appStateService.templateDownloadState"
        (click)="onDownload()">

        @if (appStateService.templateDownloadState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-download-simple"></i>
        }
      </button>
    </div>


  </div>
</ng-template>

<ng-template #UserIconRef>
  <div class="user-icon-part">
    <div
      class="user-icon-img"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu">
      {{ appStateService.user?.firstName?.charAt(0) }}{{ appStateService.user?.lastName?.charAt(0) }}
    </div>

    <div class="user-manu-list">
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="header-user-menu">

          <!--<li nz-menu-item class="user-menu-item">
            <div class="profile-info-menu-item">
              <div class="u-full-name">
                {{appStateService.user?.fullName}}
              </div>
              <div class="u-email">
                {{appStateService.user?.email}}
              </div>
            </div>
          </li>

          <li nz-menu-divider></li>-->

          <li nz-menu-item class="user-menu-item">
            <a [routerLink]="appStateService.route_paths | lngRoute: 'account' : appStateService.dateTime">
              <i class="ph ph-user"></i>
              {{ 'header.my_account' | translate }}
            </a>
          </li>

          <li nz-menu-item class="user-menu-item">
            <a [routerLink]="appStateService.route_paths | lngRoute: 'dashboard' : appStateService.dateTime">
              <i class="ph ph-file"></i>
              {{ 'header.my_documents' | translate }}
            </a>
          </li>

          @if (appStateService.user?.emailVerified) {
            @if (appStateService.user?.referAndEarn?.expired && appStateService.user?.referAndEarn?.eligible) {
              <li nz-menu-item class="user-menu-item" (click)="onReferFriend()">
                <a>
                  <i class="ph ph-gift"></i>
                  {{ 'header.refer_friend' | translate }}
                </a>
              </li>
            }
          }

          <li nz-menu-divider></li>

          <li nz-menu-item class="user-menu-item">
            <a [routerLink]="appStateService.route_paths | lngRoute: 'cv_templates' : appStateService.dateTime">
              <i class="ph ph-read-cv-logo"></i>
              {{ 'header.templates' | translate }}
            </a>
          </li>

          <li nz-menu-item class="user-menu-item">
            <a [routerLink]="appStateService.route_paths | lngRoute: 'cover_letters' : appStateService.dateTime">
              <i class="ph ph-envelope"></i>
              {{ 'header.cover_letters' | translate }}
            </a>
          </li>

          <li nz-menu-divider></li>

          <li nz-menu-item class="user-menu-item" (click)="logOut()">
            <a>
              <i class="ph ph-power"></i>
              {{ 'header.log_out' | translate }}
            </a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</ng-template>

<ng-template #NavbarRef>
  <nav>
    <ul>
      @for (item of appStateService.navMenuItems; track $index) {
        <li>
          @if (item.external) {
            <a [href]="item.route" target="_blank" rel="noopener">
              {{ item.text | translate }}
              <i class="{{item.icon}} ml-4"></i>
            </a>
          } @else {
            <a
              [routerLink]="appStateService.route_paths | lngRoute: item.route: appStateService.dateTime"
               routerLinkActive="active">
              {{ item.text | translate }}
            </a>
          }
        </li>
      }

      <!--@if (env !== 'production') {
        <li class="mr-10">
          <div class="sf-dropdown-wrapper">
            <sf-dropdown
              [unSelectable]="false"
              [additionalClassName]="'small-paddings-10'"
              [selectedItem]="appStateService.selectedLanguage"
              [items]="appStateService.languages"
              (select$)="changeLanguage($event)"
            ></sf-dropdown>
          </div>
        </li>
      }-->

    </ul>
  </nav>
</ng-template>

<ng-template #HeaderSidebarRed>
  <div class="header-sidebar-main-container">
    <div class="sd-top-part">
      @if (appStateService.user) {
        <div class="user-info-sidebar">

          <div class="sidebar-img">
            @if (appStateService.user.image) {
              <img [src]="appStateService.user.image" alt="selfcv-user">
            } @else {
              <img src="assets/img/user-empty-icon.svg" alt="selfcv">
            }
          </div>

          <div>
            <div class="fs-16 mb-4">
              {{ appStateService.user.fullName }}
            </div>
            <div class="ellipsis w-240">{{ appStateService.user.email }}</div>
          </div>

        </div>
      }

      <div class="buttons mb-20" (click)="closeSidebarMenu()">

        @if (!authService.isAuthenticated) {
          <a [routerLink]="appStateService.route_paths | lngRoute: 'sign_in' : appStateService.dateTime">
            <button class="sf-btn-bg-none">{{ 'header.log_in' | translate }}</button>
          </a>
        }

        @if (appStateService.userTemplates.length) {
          <a class="sf-btn-primary small" [routerLink]="appStateService.route_paths | lngRoute: 'dashboard' : appStateService.dateTime">
            {{ 'header.my_documents' | translate }}
          </a>
        } @else {
          <a
            class="sf-btn-tertiary small get-started"
            [routerLink]="appStateService.route_paths | lngRoute: 'cv_templates' : appStateService.dateTime">
            {{ 'header.get_started' | translate }}
          </a>
        }
      </div>

      @if (!authService.isAuthenticated) {
        <nz-divider class="m-0"/>
      }

      <nav class="pt-20">
        <ul>
          @if (authService.isAuthenticated) {
            <li (click)="closeSidebarMenu()">
              <a [routerLink]="appStateService.route_paths | lngRoute: 'account' : appStateService.dateTime">
                <i class="ph ph-user"></i>
                {{ 'header.my_account' | translate }}
              </a>
            </li>

            <li nz-menu-divider class="ant-dropdown-menu-item-divider"></li>
          }

          @for (item of appStateService.navMenuItems; track $index) {
            <li (click)="closeSidebarMenu()">

              @if (item.external) {
                <a [href]="item.route" target="_blank" rel="noopener">
                  <i class="ph ph-article"></i>
                  {{ item.text | translate }}
                  <i class="{{item.icon}} ml-4"></i>
                </a>
              } @else {
                <a [routerLink]="appStateService.route_paths | lngRoute: item.route : appStateService.dateTime" routerLinkActive="active">
                  <i class="{{item.icon}}"></i>
                  {{ item.text | translate }}
                </a>
              }
            </li>
          }

          @if (authService.isAuthenticated) {
            <li nz-menu-divider class="ant-dropdown-menu-item-divider"></li>

            <li (click)="logOut()">
              <a>
                <i class="ph ph-power"></i>
                {{ 'header.log_out' | translate }}
              </a>
            </li>
          }
        </ul>
      </nav>
    </div>

    <div class="sd-bottom-part">
        <div class="sf-dropdown-wrapper w-150">
          <sf-dropdown
            [unSelectable]="false"
            [hasSearch]="false"
            [additionalClassName]="'lng-dropdown'"
            [selectedItem]="appStateService.selectedLanguage"
            [items]="appStateService.languages"
            (select$)="changeLanguage($event)"
          />
        </div>

      <nz-divider/>

      <sf-project-social-icons [className]="'on-sidebar'"/>

      {{ projectMessages.allRights | translate }}
    </div>
  </div>
</ng-template>

<ng-template #SidebarTitleRef>
  <div class="sf-sidebar-title">
    <div class="logo-wrapper" (click)="closeSidebarMenu()">
      <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime">
        <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
        <span>selfcv</span>
      </a>
    </div>

    <i nz-icon nzType="close" nzTheme="outline" (click)="closeSidebarMenu(true)"></i>
  </div>
</ng-template>

<ng-template #CouponBannerRef>
  <div class="d-flex ai-c js-sb" style="background-color: #C8EBFF">
    <div class="sf-info-banner info ph ph-info">
      <div class="mb-0">
        <p>
          <span class="mr-8">
            <strong style="letter-spacing: 0.2px">{{ 'header.coupon_used' | translate }}</strong>
          </span>
          <span class="reset-email">
            <a class="sf-link fs-14" (click)="onCloseCouponBanner()">
              {{ 'header.activate_one_month' | translate }}
            </a>
          </span>
        </p>
      </div>
    </div>

    <i class="ph ph-x p-4 c-pointer mr-8" (click)="onCloseCouponBanner()"></i>
  </div>
</ng-template>

<ng-template #EmailUnverifiedRef>
  <div class="sf-info-banner warning ph ph-warning">
    <div>
      <p>
        <span class="mr-8" [innerHTML]="'header.verify_email' | translate"></span>
        <span class="reset-email">
          @if (emailSentState) {
            <strong>{{ 'header.email_sent' | translate }} </strong>
          } @else {
            <span class="sf-link" (click)="resentEmail()">{{ 'header.resend_email' | translate }}</span>
          }
        </span>
      </p>
    </div>
  </div>
</ng-template>

