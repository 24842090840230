import {LngKeys} from "@app/shared/enums";
import {RoutePath, RoutePaths} from "@app/shared/interfaces";
import {Router} from "@angular/router";
import {AppStateService} from "@app/shared/services";

export const getUrlLngRoute = (lngKey: LngKeys, partValue: string, routePaths: RoutePaths, newLngKey: LngKeys) => {
  // Get the object of the specified language
  const lngObject = routePaths[lngKey as keyof RoutePaths];

  // Find the key in the language object where the value contains the partValue
  const keyName = Object.keys(lngObject)
    .find((key) => lngObject[key as keyof RoutePath].includes(partValue));

  // If the key is found, get the corresponding value from the English object
  if (keyName) {
    return routePaths[newLngKey as keyof RoutePaths][keyName as keyof RoutePath];
  }

  return '';
}

export const currentLngKey = (router: Router) => {
  const paths = router.url.split('/').filter((path) => path.length);

  let lng: LngKeys = paths.at(0) as LngKeys || LngKeys.EN;
  if (!Object.values(LngKeys).includes(lng as LngKeys)) {
    lng = LngKeys.EN;
  }

  return lng;
}

export const redirectToTranslatedPath = (router: Router, newLng: LngKeys, appStateService: AppStateService) => {
  const paths = router.url.split('/').filter((path) => path.length); // [], [cv-templates], [ru], [ru, resume-documenti]
  const keyNamesPath = paths.filter((path) => !Object.values(LngKeys).includes(path as keyof RoutePaths));

  if (keyNamesPath.length === 0) {
    if (newLng === LngKeys.EN) {
      router.navigate(['/']);
    } else {
      router.navigate([newLng]);
    }

    return;
  }

  const routeValue = keyNamesPath.at(-1) as string;
  const newPath = getUrlLngRoute(currentLngKey(router), routeValue, appStateService.route_paths, newLng);

  router.navigate([newPath]);
}
