import {Pipe, PipeTransform} from '@angular/core';
import {TemplateSectionTypes} from "@app/shared/enums";
import {TemplatePage, TemplateSection, TemplateSectionItem, TemplateSettingsThemeColor} from "@app/shared/interfaces";

interface DefaultItem {
  keyName: string;
  name: string;
}

interface Defaults {
  [TemplateSectionTypes.WORK_EXPERIENCE]: DefaultItem[];
  [TemplateSectionTypes.EDUCATION]: DefaultItem[];
  [TemplateSectionTypes.TEACHING]: DefaultItem[];
  [TemplateSectionTypes.VOLUNTEER]: DefaultItem[];
  [TemplateSectionTypes.AWARDS]: DefaultItem[];
  [TemplateSectionTypes.CONFERENCES_AND_COURSES]: DefaultItem[];
  [TemplateSectionTypes.ACHIEVEMENTS]: DefaultItem[];
  [TemplateSectionTypes.ORGANIZATIONS]: DefaultItem[];
  // [TemplateSectionTypes.REFERENCES]: DefaultItem[];
  [TemplateSectionTypes.CERTIFICATES]: DefaultItem[];
}

@Pipe({
  name: 'tmThemeType',
  standalone: true
})
export class TmThemeTypePipe implements PipeTransform {
  transform(theme: TemplateSettingsThemeColor) {
    return theme;
  }
}

@Pipe({
  name: 'pageType',
  standalone: true
})
export class PageTypePipe implements PipeTransform {
  transform(page: TemplatePage) {
    return page;
  }
}

@Pipe({
  name: 'sectionType',
  standalone: true
})
export class SectionTypePipe implements PipeTransform {
  transform(section: TemplateSection) {
    return section;
  }
}

@Pipe({
  name: 'workExpSectionSt',
  standalone: true
})
export class WorkExpSectionStPipe implements PipeTransform {

  private defaults: Defaults = {
    [TemplateSectionTypes.WORK_EXPERIENCE]: [
      {keyName: 'title', name: 'tm.st.show_title'},
      {keyName: 'subTitle', name: 'tm.st.show_company_name'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
      {keyName: 'address', name: 'tm.st.show_location'},
      {keyName: 'achievements', name: 'tm.st.show_achievements'},
    ],
    [TemplateSectionTypes.EDUCATION]: [
      {keyName: 'title', name: 'tm.st.show_study_program'},
      {keyName: 'subTitle', name: 'tm.st.show_school_or_university'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
      {keyName: 'address', name: 'tm.st.show_location'},
      {keyName: 'achievements', name: 'tm.st.show_courses'},
    ],
    [TemplateSectionTypes.TEACHING]: [
      {keyName: 'title', name: 'tm.st.show_name_of_the_class'},
      {keyName: 'subTitle', name: 'tm.st.show_place_of_education'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
      {keyName: 'address', name: 'tm.st.show_location'},
      {keyName: 'achievements', name: 'tm.st.show_courses'},
    ],
    [TemplateSectionTypes.VOLUNTEER]: [
      {keyName: 'title', name: 'tm.st.show_title'},
      {keyName: 'subTitle', name: 'tm.st.show_organization'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
      {keyName: 'address', name: 'tm.st.show_location'},
      {keyName: 'achievements', name: 'tm.st.show_achievements'},
    ],
    [TemplateSectionTypes.AWARDS]: [
      {keyName: 'title', name: 'tm.st.show_award_name'},
      {keyName: 'subTitle', name: 'tm.st.show_name_of_the_institution'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
    ],
    [TemplateSectionTypes.CONFERENCES_AND_COURSES]: [
      {keyName: 'title', name: 'tm.st.show_course_name'},
      {keyName: 'subTitle', name: 'tm.st.show_subtitle'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
      {keyName: 'achievements', name: 'tm.st.show_achievements'},
    ],
    [TemplateSectionTypes.ACHIEVEMENTS]: [
      {keyName: 'title', name: 'tm.st.show_achievement_name'},
      {keyName: 'subTitle', name: 'tm.st.show_subtitle'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
    ],
    [TemplateSectionTypes.ORGANIZATIONS]: [
      {keyName: 'title', name: 'tm.st.show_organization_name'},
      {keyName: 'subTitle', name: 'tm.at.show_organization_subtitle'},
      {keyName: 'desc', name: 'tm.st.show_role'},
      {keyName: 'date', name: 'tm.st.show_period'},
    ],
    [TemplateSectionTypes.CERTIFICATES]: [
      {keyName: 'title', name: 'tm.st.show_certificate_name'},
      {keyName: 'subTitle', name: 'tm.st.show_subtitle'},
      {keyName: 'desc', name: 'tm.st.show_description'},
      {keyName: 'date', name: 'tm.st.show_period'},
    ],
    /*[TemplateSectionTypes.REFERENCES]: [
      {keyName: 'title', name: 'Show Name of Position'},
      {keyName: 'desc', name: 'Show Reference'},
      {keyName: 'email', name: 'Show Email'},
      {keyName: 'phone', name: 'Show Phone'},
    ],*/
  }

  transform(workExp: TemplateSectionItem) {
    return this.defaults[workExp.type as keyof Defaults];
  }

}
