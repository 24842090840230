import {LngKeys} from "@app/shared/enums";
import {TranslateService} from "@ngx-translate/core";
import {inject, Pipe, PipeTransform} from '@angular/core';
import {RoutePath, RoutePaths} from "@app/shared/interfaces";

@Pipe({
  name: 'lngRoute',
  standalone: true
})
export class LngRoutePipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  /** We need dateTime to run pipe */
  transform(routePaths: RoutePaths, keyName: string, dateTime = new Date().getTime()): string {
    const currentLang = this.translateService.currentLang || LngKeys.EN;
    return routePaths[currentLang as keyof RoutePaths][keyName as keyof RoutePath];
  }

}
