import copy from "copy-to-clipboard";
import {NzMessageService} from "ng-zorro-antd/message";
import {projectMessages} from "@app/shared/constants/project-messages";
import {TranslateService} from "@ngx-translate/core";

const isSafari = () => {
  let isSafari = false;
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    isSafari = true;
  }

  return isSafari;
}

export const copyToClipboard = (
  text: string,
  nzMessageService: NzMessageService | null = null,
  translateService: TranslateService | null = null
) => {
  const result = copy(text);

  if (result) {
    nzMessageService?.info(translateService?.instant(projectMessages.getTmUrl) || 'Template Url Copied Into Clipboard');
  } else {
    if (!isSafari()) {
      nzMessageService?.error(translateService?.instant(projectMessages.copyClpFailed) || 'Failed to copy text to clipboard');
    }
  }
}
